<template>
  <section>
    <h3>Job Preferences</h3>
    <div class="boxinside">
      <v-form
        ref="jobPreferenceForm"
        v-model="valid"
        lazy-validation
        class="form-section"
      >
        <div class="fullwidth">
          <label class="mr-btm">Job Category</label>
          <v-select
            v-if="allCategory.results"
            v-model="jobCategory"
            :items="allCategory.results.list"
            chips
            item-text="title"
            item-value="categoryHandle"
            label="Job Category"
            multiple
            outlined
          ></v-select>
        </div>
        <div class="fullwidth">
          <label class="mr-btm">Looking For</label>
          <v-select
            v-model="lookingfor"
            :items="lookingfors"
            label="Looking For"
            outlined
          ></v-select>
        </div>

        <div class="fullwidth">
          <label class="mr-btm">Available For</label>
          <v-select
            v-model="availableFor"
            :items="availableFors"
            chips
            label="Available For"
            multiple
            outlined
          ></v-select>
        </div>

        <div
          class="fullwidth"
          v-for="(data, index) in skills"
          :key="index + 'sk'"
        >
          <label class="mr-btm">Skill</label>
          <div class="removesection">
            <v-text-field
              v-model="data.skill"
              outlined
              label="Skill"
              class="mr-btm-minus"
            ></v-text-field>
            <div
              class="iconbox removebutton"
              @click="removeSkill(index)"
              v-if="index > 0"
            >
              <v-icon> mdi-minus-circle-outline </v-icon> Remove
            </div>
          </div>
        </div>

        <v-btn class="addmore spacebottom" @click="addSkill"
          ><v-icon> mdi-plus-circle-outline </v-icon> Add Skill</v-btn
        >
        <div
          class="fullwidth"
          v-for="(data, index) in specilizations"
          :key="index + 'sp'"
        >
          <label class="mr-btm">Specilization</label>
          <div class="removesection">
            <v-text-field
              v-model="data.specilization"
              outlined
              label="Specilization"
              class="mr-btm-minus"
            ></v-text-field>
            <div
              class="iconbox removebutton"
              @click="removeSpecilization(index)"
              v-if="index > 0"
            >
              <v-icon> mdi-minus-circle-outline </v-icon> Remove
            </div>
          </div>
        </div>

        <v-btn class="addmore spacebottom" @click="addSpecilization"
          ><v-icon> mdi-plus-circle-outline </v-icon> Add Specilization</v-btn
        >
        <label class="mr-btm labeloutside">Current Salary</label>
        <div class="fullwidth salarybox">
          <v-select
            :items="currencies"
            v-model="currencyCurrent"
            label="NRs"
            outlined
          ></v-select>
          <v-select
            :items="salaryRanges"
            v-model="salaryRangeCurrent"
            label="More"
            outlined
          ></v-select>
          <v-text-field
            v-model="currentSalary"
            label="Amount"
            outlined
          ></v-text-field>
          <v-select
            :items="salaryTimes"
            v-model="salaryTimeCurrent"
            label="Payment As"
            outlined
          ></v-select>
        </div>
        <label class="mr-btm labeloutside">Expected Salary</label>
        <div class="fullwidth salarybox">
          <v-select
            :items="currencies"
            v-model="currencyExpected"
            label="NRs"
            outlined
          ></v-select>
          <v-select
            :items="salaryRanges"
            v-model="salaryRangeExpected"
            label="More"
            outlined
          ></v-select>
          <v-text-field
            v-model="expectedSalary"
            label="Amount"
            outlined
          ></v-text-field>
          <v-select
            :items="salaryTimes"
            v-model="salaryTimeExpected"
            label="Payment As"
            outlined
          ></v-select>
        </div>

        <div class="fullwidth mr-btm">
          <label class="mr-btm">Career Object Summary</label>
          <quill-editor
            ref="myQuillEditor"
            v-model="careerObjective"
            :options="editorOption"
            @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)"
            @ready="onEditorReady($event)"
          />
        </div>

        <div
          class="fullwidth"
          v-for="(jobLocation, index) in jobPreferedLocations"
          :key="index"
        >
          <label class="mr-btm">Job Prefered Location</label>
          <div class="removesection">
            <v-text-field
              v-model="jobLocation.preferedLocation"
              outlined
              label="Job Prefered Location"
              class="mr-btm-minus"
            ></v-text-field>
            <div
              class="iconbox removebutton"
              @click="removeJobPrefered(index)"
              v-if="index > 0"
            >
              <v-icon> mdi-minus-circle-outline </v-icon> Remove
            </div>
          </div>
        </div>

        <v-btn class="addmore spacebottom" @click="addJobPrefered"
          ><v-icon> mdi-plus-circle-outline </v-icon> Add Another Preferred
          Location</v-btn
        >
        <div>
          <v-btn class="addmore" @click="addJobPreference">Next</v-btn>
        </div>
      </v-form>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Jobpreference",
  data: () => ({
    valid: true,
    id: null,
    currencies: ["Nrs", "$", "Inr"],

    jobCategory: [],

    expectedSalary: "",
    currentSalary: "",

    salaryRanges: ["Above", "Below", "Equal"],
    salaryTimes: ["Hourly", "Daily", "Weekly", "Monthly", "Yearly"],

    currencyCurrent: "",
    currencyExpected: "",
    careerObjective: "",

    salaryRangeExpected: "",
    salaryRangeCurrent: "",

    salaryTimeExpected: "",
    salaryTimeCurrent: "",

    lookingfor: "",
    lookingfors: ["Top Level", "Senior Level", "Mid Level", "Entry Level"],

    availableFor: "",
    availableFors: [
      "Full Time",
      "Part Time",
      "Temporary",
      "Contract",
      "Freelance",
      "Traineership",
      "Internship",
      "Volunteer",
    ],

    jobPreferedLocations: [{ preferedLocation: "" }],
    specilizations: [{ specilization: "" }],

    skills: [{ skill: "" }],
    editorOption: {},
  }),
  methods: {
    ...mapActions([
      "fetchCategory",
      "createJobPreference",
      "getBasicInfoUser",
      "getBasicInfoAdmin",
      "getJobPreference",
      "getBasicInfoUser",
    ]),

    addJobPrefered() {
      this.jobPreferedLocations.push({ preferedLocation: "" });
    },
    removeJobPrefered(index) {
      this.jobPreferedLocations.splice(index, 1);
    },
    addSkill() {
      this.skills.push({ skill: "" });
    },
    removeSkill(index) {
      this.skills.splice(index, 1);
    },
    addSpecilization() {
      this.specilizations.push({ specilization: "" });
    },
    removeSpecilization(index) {
      this.specilizations.splice(index, 1);
    },
    onEditorBlur(quill) {
      console.log("editor blur!", quill);
    },
    onEditorFocus(quill) {
      console.log("editor focus!", quill);
    },
    onEditorReady(quill) {
      console.log("editor ready!", quill);
    },
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
    addJobPreference() {
      let resumeDetail = {
        jobCategoris: this.jobCategory,
        lookingFor: this.lookingfor,
        available_for: this.availableFor,
        specilizations: this.specilizations,
        skills: this.skills,
        expectedSalary: {
          currency: this.currencyExpected,
          salaryRange: this.salaryRangeExpected,
          salary: this.expectedSalary,
          salaryTime: this.salaryTimeExpected,
        },
        currentSalary: {
          currency: this.currencyCurrent,
          salaryRange: this.salaryRangeCurrent,
          salary: this.currentSalary,
          salaryTime: this.salaryTimeCurrent,
        },
        careerObjective: this.careerObjective,
        jobPreferenceLocation: this.jobPreferedLocations,
      };
      if (this.id != null) {
        resumeDetail.id = this.id;
      }

      resumeDetail.resume_number = this.allBasicInfo.results.resume_number;

      this.createJobPreference(resumeDetail);

      this.$emit("success", "education");
    },
  },
  computed: {
    ...mapGetters(["allCategory", "alljobPreference", "allBasicInfo"]),
  },
  async created() {
    if (JSON.parse(localStorage.getItem("token")).user.role === "user") {
      this.getBasicInfoUser();
    } else {
      this.getBasicInfoAdmin(this.$route.params.slug);
    }

    this.fetchCategory();
    if (localStorage.getItem("token") == undefined) {
      this.$router.push("/");
    } else {
      if (this.$route.params.slug) {
        await this.getJobPreference(this.$route.params.slug);
        this.jobCategory = this.alljobPreference.results.jobCategoris;
        this.lookingfor = this.alljobPreference.results.lookingFor;
        this.availableFor = this.alljobPreference.results.available_for;
        this.specilizations = this.alljobPreference.results.specilizations;
        this.skills = this.alljobPreference.results.skills;

        this.currencyExpected = this.alljobPreference.results.expectedSalary.currency;
        this.salaryRangeExpected = this.alljobPreference.results.expectedSalary.salaryRange;
        this.expectedSalary = this.alljobPreference.results.expectedSalary.salary;
        this.salaryTimeExpected = this.alljobPreference.results.expectedSalary.salaryTime;

        this.currencyCurrent = this.alljobPreference.results.currentSalary.currency;
        this.salaryRangeCurrent = this.alljobPreference.results.currentSalary.salaryRange;
        this.currentSalary = this.alljobPreference.results.currentSalary.salary;
        this.salaryTimeCurrent = this.alljobPreference.results.currentSalary.salaryTime;

        this.careerObjective = this.alljobPreference.results.careerObjective;
        this.jobPreferedLocations = this.alljobPreference.results.jobPreferenceLocation;
        this.id = this.alljobPreference.results._id;
      } else {
        await this.getJobPreference(this.allBasicInfo.results.resume_number);
        if (this.alljobPreference.results != null) {
          this.jobCategory = this.alljobPreference.results.jobCategoris;
          this.lookingfor = this.alljobPreference.results.lookingFor;
          this.availableFor = this.alljobPreference.results.available_for;
          this.specilizations = this.alljobPreference.results.specilizations;
          this.skills = this.alljobPreference.results.skills;

          this.currencyExpected = this.alljobPreference.results.expectedSalary.currency;
          this.salaryRangeExpected = this.alljobPreference.results.expectedSalary.salaryRange;
          this.expectedSalary = this.alljobPreference.results.expectedSalary.salary;
          this.salaryTimeExpected = this.alljobPreference.results.expectedSalary.salaryTime;

          this.currencyCurrent = this.alljobPreference.results.currentSalary.currency;
          this.salaryRangeCurrent = this.alljobPreference.results.currentSalary.salaryRange;
          this.currentSalary = this.alljobPreference.results.currentSalary.salary;
          this.salaryTimeCurrent = this.alljobPreference.results.currentSalary.salaryTime;

          this.careerObjective = this.alljobPreference.results.careerObjective;
          this.jobPreferedLocations = this.alljobPreference.results.jobPreferenceLocation;
          if (this.alljobPreference.results._id) {
            this.id = this.alljobPreference.results._id;
          }
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/form.scss";
</style>
