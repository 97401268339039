<template>
  <section>
    <v-row>
      <v-col md="3">
        <ul class="leftmenu">
          <li
            v-for="(data, index) in menulists"
            :key="index"
            :class="{ activeMenu: selected == data.slug }"
          >
            <div @click="chooseMenu(data.slug)">
              <v-icon class="icon">{{ data.icon }}</v-icon> {{ data.title }}
            </div>
          </li>

          <!-- <li
            class="finalsubmit"
            @click="finalSubmitResume"
            v-if="allResumeStatus === 9 && allLogin.user.role != 'user'"
          >
            <v-icon> mdi-checkbox-marked-circle-outline </v-icon>Submit Now
          </li>
          <li
            class="finalsubmit"
            @click="finalSubmitResume"
            v-if="allResumeStatus === 9 && allLogin.user.role == 'user'"
          >
            <v-icon> mdi-checkbox-marked-circle-outline </v-icon>Done
          </li> -->
        </ul>
      </v-col>
      <v-col md="9">
        <v-row>
          <div v-if="selected == 'job_preferences'" class="fullwidth mainbox">
            <JobPreference @success="next" />
          </div>

          <div v-if="selected == 'basic_information'" class="fullwidth mainbox">
            <BasicInformation @success="next" />
          </div>

          <div v-if="selected == 'education'" class="fullwidth">
            <Education @success="next" />
          </div>

          <div v-if="selected == 'training'" class="fullwidth">
            <Training @success="next" />
          </div>

          <div v-if="selected == 'work_experience'" class="fullwidth">
            <WorkExperience @success="next" />
          </div>

          <div v-if="selected == 'language'" class="fullwidth">
            <Language @success="next" />
          </div>

          <div v-if="selected == 'social_account'" class="fullwidth">
            <SocialAccount @success="next" />
          </div>

          <div v-if="selected == 'reference'" class="fullwidth">
            <Reference @success="next" />
          </div>

          <div v-if="selected == 'other_information'" class="fullwidth mainbox">
            <OtherInformation @success="next" />
          </div>
        </v-row>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import JobPreference from "@/components/Resume/JobPreference";
import BasicInformation from "@/components/Resume/BasicInformation";
import Education from "@/components/Resume/Education";
import Training from "@/components/Resume/Training";
import WorkExperience from "@/components/Resume/WorkExperience";
import Language from "@/components/Resume/Language";
import SocialAccount from "@/components/Resume/SocialAccount";
import Reference from "@/components/Resume/Reference";
import OtherInformation from "@/components/Resume/OtherInformation";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AddResume",
  components: {
    JobPreference,
    BasicInformation,
    Education,
    Training,
    WorkExperience,
    Language,
    SocialAccount,
    Reference,
    OtherInformation,
  },
  data: () => ({
    items: ["foo", "bar", "fizz", "buzz"],
    value: ["foo", "bar", "fizz", "buzz"],
    menulists: [
      {
        title: "Basic Informations",
        slug: "basic_information",
        icon: "mdi-newspaper-variant-outline",
      },
      {
        title: "Job Prefernces",
        slug: "job_preferences",
        icon: "mdi-bag-checked",
      },
      {
        title: "Education",
        slug: "education",
        icon: "mdi-school",
      },
      {
        title: "Training",
        slug: "training",
        icon: "mdi-presentation-play",
      },
      {
        title: "Work Experience",
        slug: "work_experience",
        icon: "mdi-office-building",
      },
      {
        title: "Language",
        slug: "language",
        icon: "mdi-translate",
      },
      {
        title: "Social Account",
        slug: "social_account",
        icon: "mdi-share-variant",
      },
      {
        title: "Reference",
        slug: "reference",
        icon: "mdi-account-multiple",
      },
      {
        title: "Other Information",
        slug: "other_information",
        icon: "mdi-format-list-bulleted-triangle",
      },
    ],
    selected: "basic_information",
    valid: true,
    editorOption: {
      // Some Quill options...
    },
    content: "Demo",
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
    checkbox: false,
    outsideTravel: false,
    relocateTemporary: false,
    twoWheelerLicense: false,
    fourWheelerLicense: false,
    twoWheelerOwn: false,
    fourWheelerOwn: false,
    socialmedias: ["Facebook", "Twitter", "LinkendIn"],
  }),
  methods: {
    ...mapActions(["fetchCategory", "getResumeStatus", "finalsubmit"]),
    validate() {
      console.log(this.jobPreferedLocations);
    },
    chooseMenu(data) {
      this.selected = data;
    },

    setImage: function (output) {
      this.hasImage = true;
      this.image = output;
    },
    onEditorBlur(quill) {
      console.log("editor blur!", quill);
    },
    onEditorFocus(quill) {
      console.log("editor focus!", quill);
    },
    onEditorReady(quill) {
      console.log("editor ready!", quill);
    },
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
    removeAllElements(array, elem) {
      var index = array.indexOf(elem);
      while (index > -1) {
        array.splice(index, 1);
        index = array.indexOf(elem);
      }
    },
    finalSubmitResume() {
      this.finalsubmit();
    },
    next(e) {
      // console.log("hey");
      this.chooseMenu(e);
    },
  },
  computed: {
    ...mapGetters(["allResumeStatus", "allLogin"]),
  },
  created() {
    this.getResumeStatus();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/form.scss";
.finalsubmit {
  background: $greentva;
  color: $white;
  text-align: center;
  margin: 0;
  .v-icon {
    color: $white !important;
    margin-right: 10px;
  }
}
</style>
