<template>
  <section>
    <v-form
      ref="languageForm"
      v-model="valid"
      lazy-validation
      class="form-section"
    >
      <h3>Language</h3>
      <div
        class="fullwidth"
        v-for="(language, index) in languages"
        :key="index"
      >
        <div class="repeated-formbox">
          <div class="formbox">
            <label class="mr-btm">Language</label>
            <div class="removesection">
              <v-text-field
                v-model="language.language"
                outlined
                label="Language"
                class="mr-btm-minus"
              ></v-text-field>
            </div>
          </div>
          <div
            class="iconbox removebutton"
            @click="removeLanguage(index)"
            v-if="index > 0"
          >
            <v-icon> mdi-minus-circle-outline </v-icon> Remove
          </div>
        </div>
      </div>
      <v-btn class="addmore spacebottom" @click="addLanguage"
        ><v-icon> mdi-plus-circle-outline </v-icon> Add Language</v-btn
      >
      <div>
        <v-btn class="addmore" @click="saveLanguage">Next</v-btn>
      </div>
    </v-form>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Language",
  data: () => ({
    languages: [{ language: "" }],
    valid: true,
  }),
  methods: {
    ...mapActions(["createLanguage", "getLanguage", "getBasicInfoUser"]),
    addLanguage() {
      this.languages.push({
        language: "",
        resume_number: this.allBasicInfo.results.resume_number,
      });
      
    },
    removeLanguage(index) {
      this.languages.splice(index, 1);
    },
    saveLanguage() {
      this.createLanguage(this.languages);
       this.$emit("success", "social_account");
    },
  },
  computed: {
    ...mapGetters(["allLanguage", "allBasicInfo"]),
  },
  async created() {
    if (localStorage.getItem("token") == undefined) {
      this.$router.push("/");
    } else {
      if (this.$route.params.slug) {
        this.languages = [];
        await this.getLanguage(this.$route.params.slug);
        this.allLanguage.results.forEach((element) => {
          this.languages.push({ language: element.language, id: element._id });
        });
      } else {
        await this.getBasicInfoUser();
        if (this.allBasicInfo.results != null) {
          this.languages = [];
          await this.getLanguage(this.allBasicInfo.results.resume_number);
          this.allLanguage.results.forEach((element) => {
            this.languages.push({
              language: element.language,
              id: element._id,
              resume_number: this.allBasicInfo.results.resume_number,
            });
          });
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/form.scss";
</style>
