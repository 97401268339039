<template>
  <section>
    <v-form
      ref="referenceForm"
      v-model="valid"
      lazy-validation
      class="form-section"
    >
      <h3>Reference</h3>
      <div
        class="fullwidth"
        v-for="(reference, index) in references"
        :key="index"
      >
        <div class="repeated-formbox">
          <div class="formbox">
            <label class="mr-btm">Reference's Name</label>
            <div class="removesection">
              <v-text-field
                v-model="reference.referenceName"
                outlined
                label="Reference's Name"
                class="mr-btm-minus"
              ></v-text-field>
            </div>
            <label class="mr-btm">Job Title</label>
            <div class="removesection">
              <v-text-field
                v-model="reference.jobTitle"
                outlined
                label="Job Title"
                class="mr-btm-minus"
              ></v-text-field>
            </div>
            <label class="mr-btm">Organization Name</label>
            <div class="removesection">
              <v-text-field
                v-model="reference.organizationName"
                outlined
                label="Organization Name"
                class="mr-btm-minus"
              ></v-text-field>
            </div>
            <label class="mr-btm">Email</label>
            <div class="removesection">
              <v-text-field
                v-model="reference.email"
                outlined
                label="Email"
                class="mr-btm-minus"
              ></v-text-field>
            </div>
            <label class="mr-btm">Phone Number</label>
            <div class="removesection">
              <v-text-field
                v-model="reference.phone1"
                outlined
                label="Phone 1"
                class="mr-btm-minus"
              ></v-text-field>
            </div>
            <div class="removesection">
              <v-text-field
                v-model="reference.phone2"
                outlined
                label="Phone 2"
                class="mr-btm-minus"
              ></v-text-field>
            </div>
            <div class="removesection">
              <v-text-field
                v-model="reference.phone3"
                outlined
                label="Phone 3"
                class="mr-btm-minus"
              ></v-text-field>
            </div>
          </div>
          <div
            class="iconbox removebutton"
            @click="removeReference(index)"
            v-if="index > 0"
          >
            <v-icon> mdi-minus-circle-outline </v-icon> Remove
          </div>
        </div>
      </div>
      <v-btn class="addmore spacebottom" @click="addReference"
        ><v-icon> mdi-plus-circle-outline </v-icon> Add Reference</v-btn
      >
      <div>
        <v-btn class="addmore" @click="saveReference">Next</v-btn>
      </div>
    </v-form>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Reference",
  data: () => ({
    valid: true,
    references: [
      {
        referenceName: "",
        jobTitle: "",
        organizationName: "",
        email: "",
        phone1: "",
        phone2: "",
        phone3: "",
      },
    ],
  }),
  methods: {
    ...mapActions(["getReference", "createReference", "getBasicInfoUser"]),
    saveReference() {
      this.createReference(this.references);
      this.$emit("success", "other_information");
    },
    addReference() {
      this.references.push({
        referenceName: "",
        jobTitle: "",
        organizationName: "",
        email: "",
        phone1: "",
        phone2: "",
        phone3: "",
        resume_number: this.allBasicInfo.results.resume_number,
      });
      
    },
    removeReference(index) {
      this.references.splice(index, 1);
    },
  },
  computed: {
    ...mapGetters(["allReference", "allBasicInfo"]),
  },
  async created() {
    if (localStorage.getItem("token") == undefined) {
      this.$router.push("/");
    } else {
      if (this.$route.params.slug) {
        this.references = [];
        await this.getReference(this.$route.params.slug);
        this.allReference.results.forEach((element) => {
          this.references.push({
            referenceName: element.referenceName,
            jobTitle: element.jobTitle,
            organizationName: element.organizationName,
            email: element.email,
            phone1: element.phone1,
            phone2: element.phone2,
            phone3: element.phone3,
            id: element._id,
          });
        });
      } else {
        await this.getBasicInfoUser();
        if (this.allBasicInfo.results != null) {
          this.references = [];
          await this.getReference(this.allBasicInfo.results.resume_number);
          this.allReference.results.forEach((element) => {
            this.references.push({
              referenceName: element.referenceName,
              jobTitle: element.jobTitle,
              organizationName: element.organizationName,
              email: element.email,
              phone1: element.phone1,
              phone2: element.phone2,
              phone3: element.phone3,
              id: element._id,
              resume_number: this.allBasicInfo.results.resume_number,
            });
          });
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/form.scss";
</style>
