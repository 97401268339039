<template>
  <section>
    <v-form
      ref="trainingForm"
      v-model="valid"
      lazy-validation
      class="form-section"
    >
      <h3>Training</h3>
      <div
        class="fullwidth"
        v-for="(training, index) in trainings"
        :key="index"
      >
        <div class="repeated-formbox">
          <div class="formbox">
            <label class="mr-btm">Name of the Training</label>
            <div class="removesection">
              <v-text-field
                v-model="training.nameOfTraining"
                outlined
                label="Name of the Training"
                class="mr-btm-minus"
              ></v-text-field>
            </div>
            <label class="mr-btm">Institution Name</label>
            <div class="removesection">
              <v-text-field
                v-model="training.instituteName"
                outlined
                label="Institution Name"
                class="mr-btm-minus"
              ></v-text-field>
            </div>

            <label class="mr-btm">Duration</label>
            <div class="removesection">
              <v-text-field
                v-model="training.duration.count"
                outlined
                label="Duration"
                class="mr-btm-minus spaceright"
              ></v-text-field>
              <v-select
                v-model="training.duration.monthyear"
                :items="typetime"
                label="Month / Year"
                outlined
              ></v-select>
            </div>
            <label class="mr-btm">Completion Year</label>
            <div class="removesection">
              <v-select
                v-model="training.completionYear.year"
                :items="years"
                label="Completion Year"
                class="spaceright"
                outlined
              ></v-select>
              <v-select
                v-model="training.completionYear.month"
                :items="months"
                label="Month / Year"
                outlined
              ></v-select>
            </div>
          </div>

          <div
            class="iconbox removebutton"
            @click="removeTraining(index)"
            v-if="index > 0"
          >
            <v-icon> mdi-minus-circle-outline </v-icon> Remove
          </div>
        </div>
      </div>
      <v-btn class="addmore spacebottom" @click="addTraining"
        ><v-icon> mdi-plus-circle-outline </v-icon> Add Training</v-btn
      >
      <div>
        <v-btn class="addmore" @click="saveTraining">Next</v-btn>
      </div>
    </v-form>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Training",
  data: () => ({
    years: [],
    months: [
      "January",
      "Feburary",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    typetime: ["Month", "Year", "Days"],
    valid: true,
    trainings: [
      {
        nameOfTraining: "",
        instituteName: "",
        duration: {
          count: "",
          monthyear: "",
        },
        completionYear: {
          year: "",
          month: "",
        },
      },
    ],
  }),
  methods: {
    ...mapActions(["createTraining", "getTraining", "getBasicInfoUser"]),
    saveTraining() {
      this.createTraining(this.trainings);
        this.$emit('success','work_experience');
    },
    addTraining() {
      this.trainings.push({
        nameOfTraining: "",
        instituteName: "",
        duration: {
          count: "",
          monthyear: "",
        },
        completionYear: {
          year: "",
          month: "",
        },
        resume_number: this.allBasicInfo.results.resume_number,
      });
     
    },
    removeTraining(index) {
      this.trainings.splice(index, 1);
    },
  },
  computed: {
    ...mapGetters(["alltraining", "allBasicInfo"]),
  },
  async created() {
    for (let i = new Date().getFullYear(); i >= 1970; i--) {
      this.years.push(i);
    }
    if (localStorage.getItem("token") == undefined) {
      this.$router.push("/");
    } else {
      if (this.$route.params.slug) {
        this.trainings = [];
        await this.getTraining(this.$route.params.slug);
        this.alltraining.results.forEach((element) => {
          this.trainings.push({
            nameOfTraining: element.nameofTraining,
            instituteName: element.nameOfInstitute,
            duration: {
              count: element.duration.count,
              monthyear: element.duration.monthyear,
            },
            completionYear: {
              year: element.completionYear.year,
              month: element.completionYear.month,
            },
            id: element._id,
          });
        });
      } else {
        await this.getBasicInfoUser();
        if (this.allBasicInfo.results != null) {
          this.trainings = [];
          await this.getTraining(this.allBasicInfo.results.resume_number);
          this.alltraining.results.forEach((element) => {
            this.trainings.push({
              nameOfTraining: element.nameofTraining,
              instituteName: element.nameOfInstitute,
              duration: {
                count: element.duration.count,
                monthyear: element.duration.monthyear,
              },
              completionYear: {
                year: element.completionYear.year,
                month: element.completionYear.month,
              },
              id: element._id,
              resume_number: this.allBasicInfo.results.resume_number,
            });
          });
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/form.scss";
</style>
