<template>
  <section>
    <v-form
      ref="educationForm"
      v-model="valid"
      lazy-validation
      class="form-section"
    >
      <h3>Education</h3>
      <div
        class="fullwidth"
        v-for="(education, index) in educations"
        :key="index"
      >
        <div class="repeated-formbox">
          <div class="formbox">
            <label class="mr-btm">Degree</label>
            <div class="removesection">
              <v-select
                v-model="education.degree"
                :items="degreeses"
                label="Degree"
                outlined
              ></v-select>
            </div>
            <label class="mr-btm">Education Program</label>
            <div class="removesection">
              <v-text-field
                v-model="education.educationProgram"
                outlined
                label="Education Program"
                class="mr-btm-minus"
              ></v-text-field>
            </div>
            <label class="mr-btm">Education Board</label>
            <div class="removesection">
              <v-text-field
                v-model="education.educationBoard"
                outlined
                label="Education Board"
                class="mr-btm-minus"
              ></v-text-field>
            </div>
            <label class="mr-btm">Name of Institute</label>
            <div class="removesection">
              <v-text-field
                v-model="education.nameOfInstitute"
                outlined
                label="Name of Institute"
                class="mr-btm-minus"
              ></v-text-field>
            </div>

            <v-checkbox
              v-model="education.isCurrentlyStudying"
              label="Currently studing here?"
            ></v-checkbox>

            <label class="mr-btm" v-if="education.isCurrentlyStudying"
              >Started Year</label
            >
            <div class="removesection" v-if="education.isCurrentlyStudying">
              <v-select
                v-model="education.startedYear.year"
                :items="years"
                label="Started Year"
                class="spaceright"
                outlined
              ></v-select>
              <v-select
                v-model="education.startedYear.month"
                :items="months"
                label="Start Month"
                outlined
              ></v-select>
            </div>
            <label class="mr-btm" v-if="!education.isCurrentlyStudying"
              >Mark Secured In</label
            >
            <div class="removesection" v-if="!education.isCurrentlyStudying">
              <v-select
                v-model="education.markSecured.type"
                :items="typeMarks"
                label="Mark Secured In"
                class="spaceright"
                outlined
              ></v-select>
              <v-text-field
                v-model="education.markSecured.score"
                label="Mark Secured Count"
                outlined
              ></v-text-field>
            </div>
            <label class="mr-btm" v-if="!education.isCurrentlyStudying"
              >Graducation Year</label
            >
            <div class="removesection" v-if="!education.isCurrentlyStudying">
              <v-select
                v-model="education.graducationYear.year"
                :items="years"
                label="Graducation Year"
                class="spaceright"
                outlined
              ></v-select>
              <v-select
                v-model="education.graducationYear.month"
                :items="months"
                label="Graducation Month"
                outlined
              ></v-select>
            </div>
          </div>

          <div
            class="iconbox removebutton"
            @click="removeEducation(index)"
            v-if="index > 0"
          >
            <v-icon> mdi-minus-circle-outline </v-icon> Remove
          </div>
        </div>
      </div>
      <v-btn class="addmore spacebottom" @click="addEducation"
        ><v-icon> mdi-plus-circle-outline </v-icon> Add Education</v-btn
      >
      <div>
        <v-btn class="addmore" @click="educationSave">Next</v-btn>
      </div>
    </v-form>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Education",
  data: () => ({
    degreeses: ["SLC", "10+2", "Bachelor", "Master", "PHD"],
    valid: true,
    years: [],
    typeMarks: ["GPA", "Percentage"],
    months: [
      "January",
      "Feburary",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    educations: [
      {
        degree: "",
        educationProgram: "",
        educationBoard: "",
        nameOfInstitute: "",
        currentlyStudying: false,
        startedYear: {
          year: "",
          month: "",
        },
        markSecured: {
          type: "",
          score: "",
        },
        graducationYear: {
          year: "",
          month: "",
        },
      },
    ],
  }),
  methods: {
    ...mapActions(["createEducation", "getEducation", "getBasicInfoUser"]),
    educationSave() {
      this.createEducation(this.educations);
      this.$emit("success", "training");
    },
    addEducation() {
      this.educations.push({
        degree: "",
        educationProgram: "",
        educationBoard: "",
        nameOfInstitute: "",
        isCurrentlyStudying: false,
        startedYear: {
          year: "",
          month: "",
        },
        markSecured: {
          type: "",
          score: "",
        },
        graducationYear: {
          year: "",
          month: "",
        },
        resume_number: this.allBasicInfo.results.resume_number,
      });
    },
    removeEducation(index) {
      this.educations.splice(index, 1);
    },
  },
  computed: {
    ...mapGetters(["alleducation", "allBasicInfo"]),
  },
  async created() {
    for (let i = new Date().getFullYear(); i >= 1970; i--) {
      this.years.push(i);
    }
    if (localStorage.getItem("token") == undefined) {
      this.$router.push("/");
    } else {
      if (this.$route.params.slug) {
        this.educations = [];
        await this.getEducation(this.$route.params.slug);
        this.alleducation.results.forEach((element) => {
          this.educations.push({
            degree: element.degree,
            educationProgram: element.educationProgram,
            educationBoard: element.educationBoard,
            nameOfInstitute: element.nameOfInstitute,
            isCurrentlyStudying: element.isCurrentlyStudying,
            startedYear: {
              year: element.startedYear.year,
              month: element.startedYear.month,
            },
            markSecured: {
              type: element.markedSecuredIn.type,
              score: element.markedSecuredIn.score,
            },
            graducationYear: {
              year: element.graducationYear.year,
              month: element.graducationYear.month,
            },
            id: element._id,
          });
        });
      } else {
        this.educations = [];
        await this.getBasicInfoUser();
        if (this.allBasicInfo.results != null) {
          await this.getEducation(this.allBasicInfo.results.resume_number);
          this.alleducation.results.forEach((element) => {
            this.educations.push({
              degree: element.degree,
              educationProgram: element.educationProgram,
              educationBoard: element.educationBoard,
              nameOfInstitute: element.nameOfInstitute,
              isCurrentlyStudying: element.isCurrentlyStudying,
              startedYear: {
                year: element.startedYear.year,
                month: element.startedYear.month,
              },
              markSecured: {
                type: element.markedSecuredIn.type,
                score: element.markedSecuredIn.score,
              },
              graducationYear: {
                year: element.graducationYear.year,
                month: element.graducationYear.month,
              },
              id: element._id,
              resume_number: this.allBasicInfo.results.resume_number,
            });
          });
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/form.scss";
</style>
