<template>
  <section>
    <v-form
      ref="workExpericeForm"
      v-model="valid"
      lazy-validation
      class="form-section"
    >
      <h3>Work Experience</h3>
      <div
        class="fullwidth"
        v-for="(workexperice, index) in workexperiences"
        :key="index"
      >
        <div class="repeated-formbox">
          <div class="formbox">
            <label class="mr-btm">Organization name</label>
            <div class="removesection">
              <v-text-field
                v-model="workexperice.organizationName"
                outlined
                label="Organization name"
                class="mr-btm-minus"
              ></v-text-field>
            </div>
            <label class="mr-btm">Organization Nature</label>
            <div class="removesection" v-if="allCategory.results">
              <v-select
                v-model="workexperice.natureOfOrganization"
                :items="allCategory.results.list"
                item-text="title"
                item-value="categoryHandle"
                label="Nature of Organization"
                outlined
              ></v-select>
            </div>
            <label class="mr-btm">Job location</label>
            <div class="removesection">
              <v-text-field
                v-model="workexperice.jobLocation"
                outlined
                label="Job location"
                class="mr-btm-minus"
              ></v-text-field>
            </div>
            <label class="mr-btm">Job Category</label>
            <div class="removesection" v-if="allCategory.results">
              <v-select
                v-model="workexperice.jobCategory"
                :items="allCategory.results.list"
                item-text="title"
                item-value="title"
                label="Job Category"
                outlined
              ></v-select>
            </div>

            <label class="mr-btm">Job Title</label>
            <div class="removesection">
              <v-text-field
                v-model="workexperice.jobTitle"
                outlined
                label="Job Title"
                class="mr-btm-minus"
              ></v-text-field>
            </div>
            <label class="mr-btm">Job Level</label>
            <div class="removesection">
              <v-select
                v-model="workexperice.jobLevel"
                :items="lookingfors"
                label="Job Level"
                outlined
              ></v-select>
            </div>
            <v-checkbox
              v-model="workexperice.currenltyWorking"
              label="Currently working here?"
            ></v-checkbox>

            <label class="mr-btm">
              >Started Year</label
            >
            <div class="removesection">
              <v-select
                v-model="workexperice.startedYear.year"
                :items="years"
                label="Started Year"
                class="spaceright"
                outlined
              ></v-select>
              <v-select
                v-model="workexperice.startedYear.month"
                :items="months"
                label="Start Month"
                outlined
              ></v-select>
            </div>

            <label class="mr-btm" 
              >Ended Year</label
            >
            <div class="removesection">
              <v-select
                v-model="workexperice.endYear.year"
                :items="years"
                label="Ended Year"
                class="spaceright"
                outlined
              ></v-select>
              <v-select
                v-model="workexperice.endYear.month"
                :items="months"
                label="Ended Month"
                outlined
              ></v-select>
            </div>
            <label class="mr-btm">Duties and Responsibility</label>
            <div class="removesection editor">
              <quill-editor
                ref="myQuillEditor"
                v-model="workexperice.dutiesAndResponsibility"
                :options="editorOption"
                @blur="onEditorBlur($event)"
                @focus="onEditorFocus($event)"
                @ready="onEditorReady($event)"
              />
            </div>
          </div>

          <div
            class="iconbox removebutton"
            @click="removeWorkExperience(index)"
            v-if="index > 0"
          >
            <v-icon> mdi-minus-circle-outline </v-icon> Remove
          </div>
        </div>
      </div>
      <v-btn class="addmore spacebottom" @click="addWorkExperinces"
        ><v-icon> mdi-plus-circle-outline </v-icon> Add Work Experience</v-btn
      >
      <div>
        <v-btn class="addmore" @click="saveWorkExperience">Next</v-btn>
      </div>
    </v-form>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "WorkExperience",
  data: () => ({
    editorOption: {
      // Some Quill options...
    },
    lookingfors: ["Top Level", "Senior Level", "Mid Level", "Entry Level"],
    years: [],
    months: [
      "January",
      "Feburary",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    valid: true,
    workexperiences: [
      {
        organizationName: "",
        natureOfOrganization: "",
        jobLocation: "",
        jobTitle: "",
        jobCategory: "",
        jobLevel: "",
        currenltyWorking: false,
        startedYear: {
          year: "",
          month: "",
        },
        endYear: {
          year: "",
          month: "",
        },
        dutiesAndResponsibility: "",
      },
    ],
  }),
  methods: {
    ...mapActions([
      "createWorkExperience",
      "getWorkExperience",
      "getBasicInfoUser",
    ]),
    saveWorkExperience() {
      this.createWorkExperience(this.workexperiences);
      this.$emit("success", "language");
    },
    addWorkExperinces() {
      this.workexperiences.push({
        organizationName: "",
        natureOfOrganization: "",
        jobLocation: "",
        jobTitle: "",
        jobCategory: "",
        jobLevel: "",
        currenltyWorking: false,
        startedYear: {
          year: "",
          month: "",
        },
        endYear: {
          year: "",
          month: "",
        },
        dutiesAndResponsibility: "",
        resume_number: this.allBasicInfo.results.resume_number,
      });
    },
    removeWorkExperience(index) {
      this.workexperiences.splice(index, 1);
    },
    onEditorBlur(quill) {
      console.log("editor blur!", quill);
    },
    onEditorFocus(quill) {
      console.log("editor focus!", quill);
    },
    onEditorReady(quill) {
      console.log("editor ready!", quill);
    },
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
    ...mapActions(["fetchCategory"]),
  },
  computed: {
    ...mapGetters(["allCategory", "allWorkExperience", "allBasicInfo"]),
  },
  async created() {
    this.fetchCategory();
    for (let i = new Date().getFullYear(); i >= 1970; i--) {
      this.years.push(i);
    }
    for (let i = new Date().getFullYear(); i >= 1970; i--) {
      this.years.push(i);
    }
    if (localStorage.getItem("token") == undefined) {
      this.$router.push("/");
    } else {
      if (this.$route.params.slug) {
        this.workexperiences = [];
        await this.getWorkExperience(this.$route.params.slug);
        this.allWorkExperience.results.forEach((element) => {
          this.workexperiences.push({
            organizationName: element.oraganizationName,
            natureOfOrganization: element.natureofOragnization,
            jobLocation: element.jobLocation,
            jobTitle: element.jobTitle,
            jobCategory: element.jobCategory,
            jobLevel: element.jobLevel,
            currenltyWorking: element.isCurrentlyWorking,
            startedYear: {
              year: element.startedYear.year,
              month: element.startedYear.month,
            },
            endYear: {
              year: element.endedYear.year,
              month: element.endedYear.month,
            },
            dutiesAndResponsibility: element.dutiesAndResponsibility,
            id: element._id,
          });
        });
      } else {
        await this.getBasicInfoUser();
        if (this.allBasicInfo.results != null) {
          this.workexperiences = [];
          await this.getWorkExperience(this.allBasicInfo.results.resume_number);
          this.allWorkExperience.results.forEach((element) => {
            this.workexperiences.push({
              organizationName: element.oraganizationName,
              natureOfOrganization: element.natureofOragnization,
              jobLocation: element.jobLocation,
              jobTitle: element.jobTitle,
              jobCategory: element.jobCategory,
              jobLevel: element.jobLevel,
              currenltyWorking: element.isCurrentlyWorking,
              startedYear: {
                year: element.startedYear.year,
                month: element.startedYear.month,
              },
              endYear: {
                year: element.endedYear.year,
                month: element.endedYear.month,
              },
              dutiesAndResponsibility: element.dutiesAndResponsibility,
              id: element._id,
              resume_number: this.allBasicInfo.results.resume_number,
            });
          });
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/form.scss";
</style>
