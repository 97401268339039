<template>
  <section>
    <h3>Other Information</h3>
    <div class="boxinside">
      <v-form
        ref="otherInformationForm"
        v-model="valid"
        lazy-validation
        class="form-section"
      >
        <v-switch
          v-model="outsideTravel"
          color="primary"
          label="  Are you willing to travel outside of your residing location
                  during the job?"
          :value="true"
        ></v-switch>
        <v-switch
          v-model="relocateTemporary"
          color="primary"
          label="Are you willing to temporarily relocate outside of your residing location during the job period?"
          :value="true"
        ></v-switch>
        <v-switch
          v-model="twoWheelerLicense"
          color="primary"
          label="Do you have a two-wheeler riding license?"
          :value="true"
        ></v-switch>
        <v-switch
          v-model="fourWheelerLicense"
          color="primary"
          label="Do you have a four-wheeler driving license?"
          :value="true"
        ></v-switch>
        <v-switch
          v-model="twoWheelerOwn"
          color="primary"
          label="Do you own two-wheeler vehicle?"
          :value="true"
        ></v-switch>
        <v-switch
          v-model="fourWheelerOwn"
          color="primary"
          label="Do you own four-wheeler vehicle?"
          :value="true"
        ></v-switch>

        <div>
          <v-btn class="addmore no-space-right" @click="saveOtherInformation"
            >Save</v-btn
          >
        </div>
      </v-form>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "OtherInformation",
  data: () => ({
    valid: true,
    outsideTravel: null,
    relocateTemporary: null,
    twoWheelerLicense: null,
    fourWheelerLicense: null,
    twoWheelerOwn: null,
    fourWheelerOwn: null,
    id: null,
  }),
  methods: {
    ...mapActions([
      "createOtherInformation",
      "getOtherInformation",
      "getBasicInfoUser",
      "getResumeStatus",
      "finalsubmit",
    ]),
    async saveOtherInformation() {
      let otherinfo = {
        outsidetravel: this.outsideTravel,
        relocateTemporary: this.relocateTemporary,
        twoWheelerLicense: this.twoWheelerLicense,
        fourWheelerLicense: this.fourWheelerLicense,
        twoWheelerOwn: this.twoWheelerOwn,
        fourWheelerOwn: this.fourWheelerOwn,
        resume_number: this.allBasicInfo.results.resume_number,
      };
      if (this.id != null) {
        otherinfo.id = this.id;
      }
      await this.createOtherInformation(otherinfo);
      // await this.getResumeStatus();
      this.finalsubmit();
    },
  },
  computed: {
    ...mapGetters(["allotherInformation", "allBasicInfo"]),
  },
  async created() {
    if (localStorage.getItem("token") == undefined) {
      this.$router.push("/");
    } else {
      if (this.$route.params.slug) {
        await this.getOtherInformation(this.$route.params.slug);
        this.outsideTravel = this.allotherInformation.results[0].travelOutside;
        this.relocateTemporary = this.allotherInformation.results[0].relocated;
        this.twoWheelerLicense = this.allotherInformation.results[0].twoWheelLicense;
        this.fourWheelerLicense = this.allotherInformation.results[0].fourWheelLicense;
        this.twoWheelerOwn = this.allotherInformation.results[0].twoWheelOwn;
        this.fourWheelerOwn = this.allotherInformation.results[0].fourWheelOwn;
        this.id = this.allotherInformation.results[0]._id;
      } else {
        await this.getBasicInfoUser();
        if (this.allBasicInfo.results != null) {
          await this.getOtherInformation(
            this.allBasicInfo.results.resume_number
          );
          if (this.allotherInformation.results.length != 0) {
            this.outsideTravel = this.allotherInformation.results[0].travelOutside;
            this.relocateTemporary = this.allotherInformation.results[0].relocated;
            this.twoWheelerLicense = this.allotherInformation.results[0].twoWheelLicense;
            this.fourWheelerLicense = this.allotherInformation.results[0].fourWheelLicense;
            this.twoWheelerOwn = this.allotherInformation.results[0].twoWheelOwn;
            this.fourWheelerOwn = this.allotherInformation.results[0].fourWheelOwn;
            this.id = this.allotherInformation.results[0]._id;
          }
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/form.scss";
</style>
