<template>
  <section>
    <h3>Basic Informations</h3>
    <div class="boxinside">
      <v-form
        ref="basicForm"
        v-model="valid"
        lazy-validation
        class="form-section"
      >
        <div class="fullwidth">
          <label class="mr-btm">Full Name</label>
          <v-text-field
            v-model="fullname"
            outlined
            label="Fullname"
            class="mr-btm-minus"
            :rules="fullnameRequired"
          ></v-text-field>
        </div>
        <div class="fullwidth">
          <label class="mr-btm">Gender</label>
          <v-select
            v-model="gender"
            :items="genders"
            label="Gender"
            outlined
            item-text="label"
            item-value="value"
          ></v-select>
        </div>

        <div class="fullwidth">
          <label class="mr-btm">Date Of Birth</label>
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="dob"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dob"
                label="Date Of Birth"
                readonly
                v-bind="attrs"
                outlined
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="dob" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="$refs.dialog.save(dob)">
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </div>

        <div class="fullwidth">
          <label class="mr-btm">Maritial Status</label>
          <v-select
            v-model="maritialStatus"
            :items="maritailStatuses"
            label="Maritial Status"
            item-text="label"
            item-value="value"
            outlined
          ></v-select>
        </div>
        <div class="fullwidth">
          <v-checkbox
            v-model="hasDisability"
            label="Has any form of Disability Tick this for any kind of physical
                  disability."
          ></v-checkbox>
        </div>
        <div class="fullwidth" v-if="hasDisability">
          <label class="mr-btm">Disability</label>
          <v-text-field
            v-model="disability"
            outlined
            label="Disability"
            class="mr-btm-minus"
          ></v-text-field>
        </div>
        <div class="fullwidth">
          <label class="mr-btm">Nationality</label>

          <v-text-field
            v-model="nationality"
            outlined
            label="Nationality"
            class="mr-btm-minus"
          ></v-text-field>
        </div>
        <div class="fullwidth">
          <label class="mr-btm">Current Address</label>
          <v-text-field
            v-model="currentAddress"
            outlined
            label="Current Address"
            class="mr-btm-minus"
          ></v-text-field>
        </div>
        <div class="fullwidth">
          <label class="mr-btm">Email</label>
          <v-text-field
            v-model="email"
            outlined
            label="Email Address"
            class="mr-btm-minus"
          ></v-text-field>
        </div>
        <div class="fullwidth">
          <label class="mr-btm">Permanent Address</label>
          <v-text-field
            v-model="permanentAddress"
            outlined
            label="Permanent Address"
            class="mr-btm-minus"
          ></v-text-field>
        </div>

        <div
          class="fullwidth"
          v-for="(contact, index) in contactNumbers"
          :key="index"
        >
          <label class="mr-btm">Contact Number</label>
          <div class="removesection">
            <v-text-field
              v-model="contact.phone"
              outlined
              label="Phone Number"
              class="mr-btm-minus"
            ></v-text-field>
            <div
              class="iconbox removebutton"
              @click="removeContactNumber(index)"
              v-if="index > 0"
            >
              <v-icon> mdi-minus-circle-outline </v-icon> Remove
            </div>
          </div>
        </div>

        <v-btn class="addmore spacebottom" @click="addContactNumber"
          ><v-icon> mdi-plus-circle-outline </v-icon> Add Another Contact
          Number</v-btn
        >
        <div v-if="allLoadingResume">
          <v-btn class="addmore" @click="basicInformation">Next</v-btn>
        </div>
        <div>
          <v-progress-circular
            v-if="!allLoadingResume"
            indeterminate
          ></v-progress-circular>
        </div>
      </v-form>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "BasicInformation",
  data: () => ({
    id: null,
    contactNumbers: [{ phone: "" }],
    valid: true,
    fullname: "",
    gender: "",
    dob: "",
    email: "",
    maritialStatus: "married",
    hasDisability: false,
    disability: "",
    nationality: "",
    currentAddress: "",
    permanentAddress: "",
    maritailStatuses: [
      { label: "Married", value: "married" },
      { label: "Single", value: "single" },
      { label: "Divorced", value: "divorced" },
    ],
    genders: [
      { label: "Male", value: "male" },
      { label: "Female", value: "female" },
      { label: "Other", value: "other" },
    ],
    modal: false,
    fullnameRequired: [(v) => !!v || "Fullname is required"],
  }),
  methods: {
    ...mapActions([
      "createBasicInformation",
      "allLoadingResume",
      "getBasicInfoAdmin",
      "getBasicInfoUser",
    ]),
    basicInformation() {
      if (this.$refs.basicForm.validate()) {
        let basicInformation = {
          fullname: this.fullname,
          gender: this.gender,
          dob: this.dob,
          email: this.email,
          maritialStatus: this.maritialStatus,
          hasDisability: this.hasDisability,
          disability: this.disability,
          nationality: this.nationality,
          currentAddress: this.currentAddress,
          permanentAddress: this.permanentAddress,
          phone: this.contactNumbers,
        };

        if (this.id != null) {
          basicInformation.id = this.id;
          this.createBasicInformation(basicInformation);
        } else {
          this.createBasicInformation(basicInformation);
        }
        this.$emit("success", "job_preferences");
      }
    },
    addContactNumber() {
      this.contactNumbers.push({ phone: "" });
    },
    removeContactNumber(index) {
      this.contactNumbers.splice(index, 1);
    },
  },
  computed: {
    ...mapGetters(["allBasicInfo"]),
  },
  async created() {
    if (localStorage.getItem("token") == undefined) {
      this.$router.push("/");
    } else {
      if (this.$route.params.slug) {
        await this.getBasicInfoAdmin(this.$route.params.slug);
        this.fullname = this.allBasicInfo.results.fullname;
        this.gender = this.allBasicInfo.results.gender;
        this.dob = moment(String(this.allBasicInfo.results.dob)).format(
          "YYYY-MM-D"
        );
        (this.email = this.allBasicInfo.results.email),
          (this.maritialStatus = this.allBasicInfo.results.maritialStatus);
        this.hasDisability = this.allBasicInfo.results.hasDisability;
        this.disability = this.allBasicInfo.results.disability;
        this.nationality = this.allBasicInfo.results.nationality;
        this.currentAddress = this.allBasicInfo.results.currentAddress;
        this.permanentAddress = this.allBasicInfo.results.permanentAddress;
        this.contactNumbers = this.allBasicInfo.results.phone;
        this.id = this.allBasicInfo.results._id;
      } else {
        await this.getBasicInfoUser();
        if (this.allBasicInfo.results != null) {
          this.fullname = this.allBasicInfo.results.fullname;
          this.gender = this.allBasicInfo.results.gender;
          this.dob = moment(String(this.allBasicInfo.results.dob)).format(
            "YYYY-MM-D"
          );
          (this.email = this.allBasicInfo.results.email),
            (this.maritialStatus = this.allBasicInfo.results.maritialStatus);
          this.hasDisability = this.allBasicInfo.results.hasDisability;
          this.disability = this.allBasicInfo.results.disability;
          this.nationality = this.allBasicInfo.results.nationality;
          this.currentAddress = this.allBasicInfo.results.currentAddress;
          this.permanentAddress = this.allBasicInfo.results.permanentAddress;
          this.contactNumbers = this.allBasicInfo.results.phone;
          this.id = this.allBasicInfo.results._id;
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/form.scss";
</style>
